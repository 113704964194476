function Socials() {
  return (
    <>
      {' '}
      <div className="px-4 py-5 my-4 text-left">
        <div className="col-lg-12 mb-4">
          <div className="row">
            <div className="col-3">
              <h2>&#92;&#92; SOCIALS</h2>
            </div>
          </div>
          <div className="col-9 d-flex flex-wrap">
            <div className="p-2 flex-fill">
              <a
                href="https://www.linkedin.com/in/dylan-rijnsburger-867994163 "
                target="_blank"
                rel="noopener noreferrer"
              >
                <i style={{ fontSize: '100px' }} class="devicon-linkedin-plain"></i>
              </a>
            </div>
            <div className="p-2 flex-fill">
              <a href="https://github.com/TLWNL" target="_blank" rel="noopener noreferrer">
                <i style={{ fontSize: '100px' }} class="devicon-github-original-wordmark"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Socials;

import { Image } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

function SelectedWorks() {
  return (
    <>
      <div className="px-4 py-5 my-4 text-left">
        <div className="col-lg-12 mb-4 ">
          <hr />
          <h2>&#92;&#92; SELECTED WORKS</h2>
          <hr />

          <div className="row">
            <div className="col-md-6 col-xs-3 col-lg-6 d-flex align-items-center justify-content-center flex-column">
              <a href="https://www.tocnoordholland.nl/">
                <Image src="/TOC-TN.png" height={isMobile ? 200 : 400} width={isMobile ? 300 : 600}></Image>
              </a>
              <div>
                <p className="lead">&#92;&#92; TOC Noord-Holland</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 d-flex align-items-center justify-content-center flex-column">
              <a href="https://www.bobbyveldhuizen.com/">
                <Image src="/Bobby.png" height={isMobile ? 200 : 400} width={isMobile ? 300 : 600}></Image>
              </a>
              <div>
                <p className="lead">&#92;&#92; Bobby Veldhuizen</p>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </>
  );
}

export default SelectedWorks;

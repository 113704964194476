function Services() {
  return (
    <>
      {' '}
      <div className="px-4 py-5 my-5">
        <h2 className="text-left mb-4">&#92;&#92; SERVICES</h2>

        <div className="col-lg-6  mx-auto">
          <div className="row">
            <div className="col">
              <p className="lead">Web Design</p>
            </div>
            <div className="col">
              <p className="lead">Web Development</p>
            </div>
            <div className="col">
              <p className="lead">App Development</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Services;

function AboutMe() {
  return (
    <>
      {' '}
      <div className="px-4 py-5 my-5 text-left">
        <div className="col-lg-6 mb-4">
          <h2>&#92;&#92; ABOUT ME</h2>
          <p className="lead mb-4"> Behind every website or software application is a person with a vision. </p>
          <p className="lead mb-4">
            {' '}
            Passionate about every aspect of software development, spanning from Back-End Java engineering to front-end
            Web Design and development, and encompassing everything in between, TLW Solutions stands as the ideal
            partner for crafting a solution for you that guarantees increased traffic using modern techniques and
            technologies.{' '}
          </p>
        </div>
      </div>
    </>
  );
}

export default AboutMe;

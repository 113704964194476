function TechStack() {
  return (
    <>
      <div className="px-4 py-5 my-4 text-left">
        <div className="col-lg-12 mb-4">
          <div className="row">
            <div className="col-3">
              <h2>&#92;&#92; TECH STACK</h2>
            </div>
            <div className="col-9 d-flex flex-wrap">
              <div className="p-2 flex-fill">
                {' '}
                <i style={{ fontSize: '100px' }} class="devicon-spring-plain-wordmark"></i>
              </div>
              <div className="p-2 flex-fill">
                {' '}
                <i style={{ fontSize: '100px' }} class="devicon-c-line"></i>
              </div>
              <div className="p-2 flex-fill">
                {' '}
                <i style={{ fontSize: '100px' }} class="devicon-cplusplus-line"></i>
              </div>
              <div className="p-2 flex-fill">
                {' '}
                <i style={{ fontSize: '100px' }} class="devicon-docker-plain"></i>
              </div>
              <div className="p-2 flex-fill">
                {' '}
                <i style={{ fontSize: '100px' }} class="devicon-gitlab-plain-wordmark"></i>
              </div>
              <div className="p-2 flex-fill">
                {' '}
                <i style={{ fontSize: '100px' }} class="devicon-java-plain-wordmark"></i>
              </div>
              <div className="p-2 flex-fill">
                {' '}
                <i style={{ fontSize: '100px' }} class="devicon-linux-plain"></i>
              </div>
              <div className="p-2 flex-fill">
                {' '}
                <i style={{ fontSize: '100px' }} class="devicon-mongodb-plain-wordmark"></i>
              </div>
              <div className="col">
                {' '}
                <i style={{ fontSize: '100px' }} class="devicon-react-original-wordmark"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TechStack;

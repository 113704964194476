import { Button, Modal } from 'react-bootstrap';
import { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';

function Contact({ full }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    console.log(e);

    emailjs.sendForm('service_u0uqesy', 'template_bha5jef', form.current, 'Hoiwsx3zbCUlvKTxq').then(
      (result) => {
        console.log(result.text);
        handleClose();
      },
      (error) => {
        console.log(error.text);
      }
    );
  };

  return (
    <>
      {' '}
      <div className="px-4 py-5 my-4 text-left">
        <div className="col-lg-12 mb-4">
          <div className="row">
            <div className="col-3 col-xs-6 ">
              <h2>&#92;&#92; CONTACT</h2>
            </div>
          </div>

          <div className="col-6 col-xs-12 mt-4">
            <p className="lead col-xs-12">
              Ready to bring your vision to life or just want to chat? Reach out, and let's create something memorable
              together.<br></br> I'm here to listen, collaborate, and craft solutions that resonate.
            </p>

            {full ? (
              <form ref={form} onSubmit={sendEmail}>
                <div className="form-outline mb-4">
                  <input type="text" className="form-control" />
                  <label className="form-label">Name</label>
                </div>

                <div className="form-outline mb-4">
                  <input type="email" className="form-control" />
                  <label className="form-label">Email address</label>
                </div>

                <div className="form-outline mb-4">
                  <textarea id="textarea4" rows="4" className="form-control"></textarea>
                  <label className="form-label">Your message</label>
                </div>

                <button type="submit" className="btn btn-primary btn-block">
                  Send
                </button>
              </form>
            ) : (
              <>
                <Button variant="primary" onClick={handleShow}>
                  Get in touch!
                </Button>

                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Contact</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <form ref={form} onSubmit={sendEmail}>
                      <div className="form-outline mb-4">
                        <input type="text" className="form-control" />
                        <label className="form-label">Name</label>
                      </div>

                      <div className="form-outline mb-4">
                        <input type="email" className="form-control" />
                        <label className="form-label">Email address</label>
                      </div>

                      <div className="form-outline mb-4">
                        <textarea id="textarea4" rows="4" className="form-control"></textarea>
                        <label className="form-label">Your message</label>
                      </div>

                      <button type="submit" className="btn btn-primary btn-block">
                        Send
                      </button>
                    </form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;

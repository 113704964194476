import { Helmet } from 'react-helmet';

function Hero() {
  const title = 'TLW SOLUTIONS';

  return (
    <>
      {' '}
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="px-4 py-5 my-5 text-left">
        <h1 className="display-5 fw-bold">
          TLW <br></br>SOLUTIONS
        </h1>
        <div className="col-lg-6 ">
          <p className="lead mb-4">
            {' '}
            Hi! I'm Dylan Rijnsburger, a passionate creative fueled by innovation and detail.
          </p>
        </div>
      </div>
    </>
  );
}

export default Hero;

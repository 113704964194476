import Hero from '../components/Hero';
import AboutMe from '../components/AboutMe';
import Services from '../components/Services';
import SelectedWorks from '../components/SelectedWorks';
import Experience from '../components/Experience';
import TechStack from '../components/TechStack';
import Socials from '../components/Socials';
import Contact from '../components/Contact';

function Home() {
  return (
    <>
      <main className="container-fluid">
        <Hero></Hero>
        <AboutMe></AboutMe>
        <Services></Services>
        <SelectedWorks></SelectedWorks>
        <Experience></Experience>
        <TechStack></TechStack>
        <Socials></Socials>
        <Contact full={false}></Contact>
      </main>
    </>
  );
}

export default Home;

import { NavLink } from 'react-router-dom';

import './navbar.css';
//     { path: '/blog', title: 'Blog' },

function MainNavbar() {
  const items = [
    { path: '/', title: 'Home' },
    { path: '/portfolio', title: 'Portfolio' },
    { path: '/contact', title: 'Contact' },
  ];

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container-fluid">
        <a className="navbar-brand d-flex pt-2" href="/">
          <img src="/android-chrome.png" alt="Tlw Logo" className="navbar-logo" />
          &#32;&#09;TLW Solutions
        </a>
        <button
          className="navbar-toggler collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarMenu"
          aria-controls="navbarMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        {/* TODO: Move these items to the right */}
        <div className="collapse navbar-collapse justify-content-end" id="navbarMenu">
          <ul className="navbar-nav">
            {items.map((item, i) => (
              <li key={i} className="nav-item">
                <NavLink className="nav-link" to={item.path}>
                  {item.title}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default MainNavbar;

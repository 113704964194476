function Experience() {
  return (
    <>
      <div className="px-4 py-5 my-4 text-left">
        <div className="col-lg-12 mb-4 ">
          <div className="row">
            <div className="col-12 ">
              <h2>&#92;&#92; EXPERIENCE</h2>
            </div>
            <div className="col-12">
              <table class="table table-dark">
                <tbody>
                  <tr>
                    <td>Owner and Lead Developer</td>
                    <td>TLW Solutions</td>
                    <td>2023</td>
                  </tr>
                  <tr>
                    <td>Java Developer</td>
                    <td>Lost Lemon</td>
                    <td>2020 - 2022</td>
                  </tr>
                  <tr>
                    <td>Bachelor Computer Science</td>
                    <td>VU Amsterdam</td>
                    <td>2017-2021</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Experience;

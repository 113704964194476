import { Image } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

function Portfolio() {
  return (
    <>
      <div className="px-4 py-5 my-5 text-center">
        <div className="col-lg-12 mb-4">
          <h2>&#92;&#92; PORTFOLIO</h2>
          <p className="lead mb-4"> Explore my works and ideas </p>

          <div className="row">
            {/* {items.map((item, i) => ( */}
            <div className="col md-6 lg-6 d-flex align-items-center justify-content-center flex-column">
              <Image src="/TOC-TN.png" height={isMobile ? 200 : 400} width={isMobile ? 300 : 600}></Image>
              <div>
                <p className="lead">&#92;&#92; TOC Noord-Holland</p>
                <p className="lead ">
                  A website made with React, Bootstrap and ExpressJS. <br /> A simple design strategy and a clear
                  layout, alongside a strong backend.
                </p>
              </div>
            </div>
            <div className="col md-6 lg-6 d-flex align-items-center justify-content-center flex-column">
              <Image src="/Bobby.png" height={isMobile ? 200 : 400} width={isMobile ? 300 : 600}></Image>
              <div>
                <p className="lead">&#92;&#92; Bobby Veldhuizen</p>
                <p className="lead ">
                  A website made with React, Bootstrap and ExpressJS. <br /> A darkmode design, typewriter effect and a
                  one page layout.
                </p>
              </div>
            </div>
            <div className="col md-6 lg-6 d-flex align-items-center justify-content-center flex-column">
              <Image src="/lichess.png" height={isMobile ? 200 : 400} width={isMobile ? 300 : 600}></Image>
              <div>
                <p className="lead">&#92;&#92; ChessPuzzler</p>
                <p className="lead ">
                  <i>Dev status: alpha.</i> A chess puzzle app that connects to Lichess, and provides functionality that
                  is not ported from Lichess to the Lichess app. <br></br>
                  Made using React Native for cross platform and web use.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>{' '}
    </>
  );
}
export default Portfolio;
